exports = module.exports = require("C:\\addon-agent\\Build Files\\Other\\SDK\\1.0.30.11\\Sources\\SDK\\Sana.Commerce.WebApp\\ClientApp\\node_modules\\css-loader\\lib\\css-base.js")(false);
// imports


// module
exports.push([module.id, ".ProductSetCarouselContentBlock__lazyloader_default-placeholder{height:100%;background-color:#f7f7f7;width:100%;background-clip:content-box;box-sizing:content-box;display:block;border-radius:5px;min-height:1em}", ""]);

// exports
exports.locals = {
	"default-placeholder": "ProductSetCarouselContentBlock__lazyloader_default-placeholder",
	"defaultPlaceholder": "ProductSetCarouselContentBlock__lazyloader_default-placeholder"
};